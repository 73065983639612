import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'
import {
    StaticImage,
    GatsbyImage,
    getImage,
    withArtDirection,
} from 'gatsby-plugin-image'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import SwiperCore, { EffectCoverflow, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

SwiperCore.use([EffectCoverflow, Pagination])

const useStyles = (props) =>
    makeStyles((theme) => ({
        wrapper: {
            paddingTop: '100px',
            paddingBottom: '200px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '30px',
                paddingBottom: '50px',
            },
        },
        slideCard: {
            borderRadius: '5%',
            background: 'rgba(117, 204, 189, 0.2)',
        },
        slideCardContainer: {
            padding: '50px 15px 50px 15px',
        },
        header: {
            color: '#75CCBD',
            fontSize: '1.125rem',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        generalTitle: {
            fontSize: '3.375rem',
            color: '#0F0518',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
            },
        },
        backgroundStripes1: {
            width: '8%',
            height: '24px',
            position: 'absolute',
            [theme.breakpoints.down('sm')]: {
                width: '60px',
                height: '13px',
            },
        },
        backgroundStripes2: {
            width: '8%',
            height: '24px',
            position: 'absolute',
            top: '-50px',
            [theme.breakpoints.down('sm')]: {
                width: '60px',
                height: '13px',
                top: '-25px',
            },
        },
        backgroundThreeDots: {
            position: 'absolute',
            right: '0px',
            top: '-50px',
            [theme.breakpoints.down('sm')]: {
                width: '60px',
                top: '-40px',
            },
        },
        backgroundCircleGreen: {
            position: 'absolute',
            left: '10px',
            top: '0px',
            [theme.breakpoints.down('sm')]: {
                width: '20px',
            },
        },
        backgroundCircleOrange: {
            position: 'absolute',
            left: '10px',
            top: '50px',
            [theme.breakpoints.down('sm')]: {
                width: '20px',
                top: '25px',
            },
        },
        backgroundCircleHalf: {
            position: 'absolute',
            right: '0px',
            top: '50px',
            [theme.breakpoints.down('sm')]: {
                width: '20px',
                top: '25px',
            },
        },
        subtitle: {
            fontSize: '1.5rem',
            color: '#0F0518',
            paddingBottom: '50px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        image: {
            borderRadius: '50%',
            transform: 'translateZ(0)',
        },
        title: {
            fontSize: '1.5rem',
        },
        course: {
            fontWeight: 700,
            color: '#75CCBD',
            fontSize: '1.125rem',
        },
        text: {
            fontSize: '1.25rem',
            color: '#0F051899',
            lineHeight: '34px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        icons: {
            marginTop: '20px',
            marginBottom: '20px',
        },
    }))

const RichText = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    const hasTitle = Boolean(data.title)
    const hasSubtitle = Boolean(data.title)
    const hasHeader = Boolean(data.header)

    return (
        <Box className={classes.wrapper}>
            <Grid container style={{ position: 'relative' }}>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/orange_rectangle.png"
                        alt="rectangle1"
                        className={classes.backgroundStripes1}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/three_dots.png"
                        alt="rectangle1"
                        className={classes.backgroundThreeDots}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StaticImage
                        src="../../assets/background_icons/orange_rectangle.png"
                        alt="rectangle2"
                        className={classes.backgroundStripes2}
                    />
                </Grid>
            </Grid>
            <Container maxWidth="lg">
                {hasHeader && (
                    <Typography className={classes.header} align="center">
                        {data.header}
                    </Typography>
                )}
                {hasTitle && (
                    <Typography className={classes.generalTitle} align="center">
                        {data.title}
                    </Typography>
                )}
                {hasSubtitle && (
                    <Typography className={classes.subtitle} align="center">
                        {data.subtitle}
                    </Typography>
                )}
                <Swiper
                    style={{
                        '--swiper-pagination-color': '#F1571E',
                        height: 'auto',
                        paddingBottom: '30px',
                    }}
                    swiperOptions={{
                        preventClicks: false,
                        preventClicksPropagation: false,
                        slideToClickedSlide: true,
                    }}
                    breakpoints={{
                        // when window width is >= 640px
                        320: {
                            width: 320,
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                    }}
                    spaceBetween={10}
                    pagination={{ clickable: true }}
                    onSlideChange={() => true}
                    onSwiper={(swiper) => true}
                >
                    {data.cards.map((input) => {
                        const hasTitle = Boolean(input.title)
                        const hasCourse = Boolean(input.course)
                        const hasText = Boolean(input.text)
                        const hasIcons = Boolean(input.icons)
                        const hasImage = Boolean(input.image)

                        const images = getImage(input.image)
                        const icons = getImage(input.icons)
                        return (
                            <>
                                <SwiperSlide className={classes.slideCard}>
                                    <Grid
                                        className={classes.slideCardContainer}
                                        container
                                    >
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={4}>
                                            {hasImage && (
                                                <GatsbyImage
                                                    image={images}
                                                    alt={input.title}
                                                    className={classes.image}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            style={{
                                                textAlign: 'center',
                                                marginTop: '50px',
                                            }}
                                        >
                                            {hasTitle && (
                                                <Typography
                                                    className={classes.title}
                                                >
                                                    {input.title}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            style={{
                                                textAlign: 'center',
                                                marginTop: '30px',
                                            }}
                                        >
                                            {hasCourse && (
                                                <Typography
                                                    className={classes.course}
                                                >
                                                    {input.course}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        {hasIcons && (
                                            <Grid container xs={12}>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4}>
                                                    <GatsbyImage
                                                        image={icons}
                                                        alt={input.title}
                                                        className={
                                                            classes.icons
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            {hasText && (
                                                <Typography
                                                    className={classes.text}
                                                >
                                                    {renderRichText(
                                                        input.text,
                                                        options()
                                                    )}
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </SwiperSlide>
                            </>
                        )
                    })}
                </Swiper>
            </Container>
            <Grid container style={{ position: 'relative' }}>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/circle_green.png"
                        alt="green circle"
                        className={classes.backgroundCircleGreen}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StaticImage
                        src="../../assets/background_icons/circle_green_half.png"
                        alt="green half-circle"
                        className={classes.backgroundCircleHalf}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StaticImage
                        src="../../assets/background_icons/circle_orange.png"
                        alt="orange circle"
                        className={classes.backgroundCircleOrange}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default RichText
