import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CountUp from '../../components/CountUp'
import {
    StaticImage,
    GatsbyImage,
    getImage,
    withArtDirection,
} from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingTop: '200px',
        paddingBottom: '200px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '80px',
            paddingBottom: '100px',
        },
    },
    wrapper2: {
        paddingTop: '70px',
        paddingBottom: '70px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
        },
    },
    heading: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: '#75CCBD',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem',
        },
    },
    title: {
        fontSize: '1.75rem',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem',
        },
    },
    title2: {
        fontSize: '2.25rem',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.25rem',
        },
    },
    text: {
        fontSize: '1.125rem',
        color: '#0F0518',
        fontWeight: 400,
        minWidth: '200px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '30px',
            fontSize: '0.875rem',
        },
    },
    backgroundStripes1: {
        width: '8%',
        height: '24px',
        position: 'absolute',
        top: '-150px',
        right: '0px',

        [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '13px',
            top: '-35px',
        },
    },
    backgroundStripes2: {
        width: '8%',
        height: '24px',
        position: 'absolute',
        top: '-100px',
        right: '0px',

        [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '13px',
            top: '-10px',
        },
    },
    backgroundCircles: {
        position: 'absolute',
        top: '-15px',
        [theme.breakpoints.down('sm')]: {
            width: '40px',
            top: '25px',
        },
    },
    imageBox: {
        width: '40px',
    },
    logoBox: {
        width: '150px',
    },
    imageBox2: {
        width: '77px',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
        },
    },
    h1: {
        fontSize: '3.375rem',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.125rem',
        },
    },
    link: {
        height: '100%',
        fontSize: '1.25rem',
        fontWeight: 'normal',
        textDecoration: 'none',
        color: theme.palette.secondary.black,
        '&:hover': {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.text.black,
        },
    },
}))

const getBackgroundColor = (color) =>
    color === 'Green' ? 'background.green' : 'background.default'

export default (props) => {
    const data = props.data[props.componentId]

    const classes = useStyles()

    const bgcolor = getBackgroundColor(data.backgroundColor)

    const getNoOfBoxesPerRow = (input) => {
        return input === 'Three boxes per row' ? 3 : 1
    }

    const noOfBoxesPerRow = getNoOfBoxesPerRow(data.noOfBoxesPerRow)

    const getOrientation = (
        orientation,
        tile,
        image,
        title,
        text,
        animationSpeed
    ) => {
        const hasTitle = Boolean(title)
        const hasText = Boolean(text)
        const hasImage = Boolean(image)

        const whatImage = (input) => {
            return input === 'image/svg+xml' ? 'svg' : 'png'
        }

        const hasWhatImage = Boolean(
            image && image.file && image.file.contentType
        )

        const whatFormat = hasWhatImage && whatImage(image.file.contentType)

        const images = getImage(tile.image)

        const whatTitle = (animationSpeed) => {
            if (animationSpeed === null) {
                return (
                    <Typography variant="h2" className={classes.title}>
                        {tile.title}
                    </Typography>
                )
            }
            return (
                <Typography variant="h2" className={classes.title}>
                    <CountUp
                        start={0}
                        end={newTitle}
                        timer={tile.animationSpeed}
                    />
                </Typography>
            )
        }

        const titleFilter = (input) => {
            return parseInt(input)
        }

        const newTitle = titleFilter(tile.title)

        const typeOfBox = getTypeOfBox(data.typeOfBox)
        const isSoloLogoBox =
            noOfBoxesPerRow === 1 && typeOfBox === classes.logoBox

        if (orientation === 'Image(top) -> Text(bottom)') {
            return (
                <React.Fragment>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12}>
                            {hasImage &&
                                whatFormat === 'png' &&
                                !isSoloLogoBox && (
                                    <Box className={typeOfBox}>
                                        <GatsbyImage
                                            image={images}
                                            alt={tile.title}
                                        />
                                    </Box>
                                )}
                            {hasImage &&
                                whatFormat === 'svg' &&
                                !isSoloLogoBox && (
                                    <Box className={typeOfBox}>
                                        <img src={image.file.url} />
                                    </Box>
                                )}
                            {isSoloLogoBox &&
                                hasImage &&
                                whatFormat === 'png' && (
                                    <Box
                                        className={`${typeOfBox} ${classes.logoSoloBox}`}
                                        style={{ width: '250px' }}
                                    >
                                        <GatsbyImage
                                            image={images}
                                            alt={tile.title}
                                        />
                                    </Box>
                                )}
                            {isSoloLogoBox &&
                                hasImage &&
                                whatFormat === 'svg' && (
                                    <Box
                                        className={`${typeOfBox} ${classes.logoSoloBox}`}
                                        style={{ width: '250px' }}
                                    >
                                        <img src={image.file.url} />
                                    </Box>
                                )}
                        </Grid>
                        <Grid item xs={12}>
                            {hasTitle && whatTitle(tile.animationSpeed)}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                paddingLeft: '20px',
                                paddingRight: '20px',
                            }}
                        >
                            {hasText && (
                                <Typography
                                    variant="h6"
                                    align="center"
                                    className={classes.text}
                                >
                                    {tile.text}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
        return (
            <Box pb={2}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={3}
                >
                    <Grid item xs={4}>
                        {hasImage && whatFormat === 'png' && (
                            <Box
                                style={{
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                                className={typeOfBox}
                            >
                                <GatsbyImage image={images} alt={tile.title} />
                            </Box>
                        )}
                        {hasImage && whatFormat === 'svg' && (
                            <Box
                                style={{
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                                className={typeOfBox}
                            >
                                <img src={image.file.url} />
                            </Box>
                        )}
                    </Grid>
                    <Grid item container xs={6}>
                        <Grid item xs={12}>
                            {hasTitle && whatTitle(tile.animationSpeed)}
                        </Grid>
                        <Grid item xs={12}>
                            {hasText && (
                                <Typography
                                    variant="h6"
                                    className={classes.text}
                                >
                                    {tile.text}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item container xs={2}></Grid>
                </Grid>
            </Box>
        )
    }

    const backgroundStyling = (input) => {
        return input === 'Yes' ? true : false
    }

    const hasTitle = Boolean(data.title)
    const hasHeading = Boolean(data.heading)
    const getBackgroundStyling = backgroundStyling(data.backgroundStyling)

    const pickWrapper = (input) => {
        return input === true ? classes.wrapper : classes.wrapper2
    }

    const getPickedWrapper = pickWrapper(getBackgroundStyling)

    const getNoOfRows = (input) => {
        return input === 'Three boxes per row' ? 4 : 3
    }

    const getTypeOfBox = (input) => {
        return input === 'Logo' ? classes.logoBox : classes.imageBox
    }
    const noOfRows = getNoOfRows(data.noOfBoxesPerRow)

    const typeOfBox = getTypeOfBox(data.typeOfBox)

    const noOfRowsMobile = data.content.length % 2 !== 0 ? 12 : 5

    const link = (name, pathPrefix) =>
        name === 'index'
            ? '/'
            : pathPrefix
            ? `/${pathPrefix}/${name}`
            : `/${name}`

    return (
        <Box bgcolor={bgcolor} className={getPickedWrapper}>
            {getBackgroundStyling && (
                <Box>
                    <Grid container style={{ position: 'relative' }}>
                        <Grid item xs={12}>
                            <StaticImage
                                src="../../assets/background_icons/orange_rectangle.png"
                                alt="rectangle1"
                                // width={width}
                                // height={height}
                                className={classes.backgroundStripes1}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StaticImage
                                src="../../assets/background_icons/orange_rectangle.png"
                                alt="rectangle2"
                                // width={width}
                                // height={height}
                                className={classes.backgroundStripes2}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Container maxWidth="lg">
                {hasHeading && (
                    <Box textAlign="center" className={classes.heading}>
                        {data.heading}
                    </Box>
                )}
                {hasTitle && (
                    <Box textAlign="center" className={classes.h1} pb={6}>
                        {data.title}
                    </Box>
                )}
                <Grid container direction="row" justifyContent="space-evenly">
                    {data.content &&
                        data.content.map((tile, index) => {
                            const hasLink = Boolean(tile.link && tile.link.name)
                            return (
                                <Grid
                                    item
                                    xs={noOfRowsMobile}
                                    lg={noOfRows}
                                    key={index}
                                    container
                                    // justifyContent="center"
                                    alignItems="center"
                                    style={{ paddingBottom: '48px' }}
                                >
                                    {hasLink ? (
                                        <Link
                                            to={
                                                hasLink &&
                                                link(
                                                    tile.link.name,
                                                    tile.link.pathPrefix
                                                )
                                            }
                                            className={classes.link}
                                        >
                                            {getOrientation(
                                                tile.orientation,
                                                tile,
                                                tile.image,
                                                tile.title,
                                                tile.text
                                            )}
                                        </Link>
                                    ) : (
                                        <>
                                            {getOrientation(
                                                tile.orientation,
                                                tile,
                                                tile.image,
                                                tile.title,
                                                tile.text
                                            )}
                                        </>
                                    )}
                                </Grid>
                            )
                        })}
                </Grid>
            </Container>
            {getBackgroundStyling && (
                <Grid container style={{ position: 'relative' }}>
                    <Grid item xs={12}>
                        <StaticImage
                            src="../../assets/background_icons/three_dots_left.png"
                            alt="green circle"
                            className={classes.backgroundCircles}
                        />
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}
