import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const useStyles = (props) =>
    makeStyles((theme) => ({
        title: {
            fontSize: '2.25rem',
            fontWeight: '700',
            fontFamily: 'IBM Plex Mono',
            paddingBottom: '4rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                paddingBottom: '2rem',
            },
        },
        image: {
            width: '75px',
            height: '75px',
            [theme.breakpoints.down('sm')]: {
                width: '30px',
                height: '30px',
            },
        },
        text: {
            fontSize: '1.5rem',
            fontWeight: '400',
            color: 'rgba(15, 5, 24, 0.6)',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        entryTitle: {
            fontSize: '1.75rem',
            fontWeight: '600,',
            fontFamily: 'IBM Plex Mono',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
    }))

const RainbowStrips = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    const hasTitle = Boolean(data.tile)

    return (
        <Box pt={8}>
            <Container maxWidth="lg">
                {hasTitle && (
                    <Typography className={classes.title}>
                        {data.title}
                    </Typography>
                )}
            </Container>
            <Grid item container xs={12}>
                {data.imageText.map((input) => {
                    const getBackgroundColor = (backgroundColor) =>
                        backgroundColor === 'creme' ? 'background.default' : ''

                    const bgcolor = getBackgroundColor(data.backgroundColor)

                    const hasImage = Boolean(input.image)
                    const hasText = Boolean(input.text)
                    const hasEntrytitle = Boolean(input.entryTitle)

                    const image = getImage(input.image)

                    return (
                        <Box
                            py={3}
                            bgcolor={input.backgroundColor}
                            style={{ width: '100%' }}
                        >
                            <Container maxWidth="lg">
                                <Grid container alignItems="center">
                                    <Grid item xs={3} md={2}>
                                        {hasImage && (
                                            <GatsbyImage
                                                className={classes.image}
                                                image={image}
                                                alt={input.image.title}
                                            />
                                        )}
                                    </Grid>
                                    <Grid xs={9} md={10}>
                                        {hasEntrytitle && (
                                            <Typography
                                                className={classes.entryTitle}
                                            >
                                                {input.entryTitle}
                                            </Typography>
                                        )}
                                        {hasText && (
                                            <Typography
                                                className={classes.text}
                                            >
                                                {renderRichText(
                                                    input.text,
                                                    options()
                                                )}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default RainbowStrips
